var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c("loader"),
      _c(
        "AppHeader",
        { attrs: { fixed: "" } },
        [
          _c("SidebarToggler", {
            staticClass: "d-lg-none",
            attrs: { display: "md", mobile: "" }
          }),
          _c("SidebarToggler", {
            staticClass: "d-md-down-none",
            attrs: { display: "lg", defaultOpen: true }
          }),
          _c("b-link", { staticClass: "navbar-brand", attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "navbar-brand-full",
              attrs: {
                src: "img/brand/logo.png",
                height: "22",
                alt: "Taylor Logo"
              }
            })
          ]),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _vm.isTest
                ? _c("span", { staticClass: "badge badge-warning" }, [
                    _vm._v("Ambiente de Teste")
                  ])
                : _vm._e(),
              _c("DefaultHeaderDropdownAccntList")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "app-body" },
        [
          _c(
            "AppSidebar",
            { attrs: { fixed: "", minimized: "" } },
            [
              _c("SidebarHeader"),
              _c("SidebarForm"),
              _c("SidebarNav", { attrs: { navItems: _vm.navigation } }),
              _c("SidebarFooter"),
              _c("SidebarMinimizer")
            ],
            1
          ),
          _c("main", { staticClass: "main" }, [
            _c(
              "div",
              {
                staticClass: "text-primary font-weight-bold h4 ml-3 mt-2 mb-0"
              },
              [
                _c("i", { staticClass: "icons", class: _vm.routeIcon }),
                _vm._v(" " + _vm._s(_vm.routeName) + "\n      ")
              ]
            ),
            _c(
              "div",
              { staticClass: "container-fluid p-2" },
              [_c("router-view")],
              1
            )
          ]),
          _c("AppAside", { attrs: { fixed: "" } }, [_c("DefaultAside")], 1)
        ],
        1
      ),
      _c("Footer", { staticClass: "app-footer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }