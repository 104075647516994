var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("button", {
    staticClass: "sidebar-minimizer",
    attrs: { type: "button" },
    on: {
      click: function($event) {
        return _vm.onClick()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }