var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { class: _vm.classList }, [
    _c("div", { staticClass: "ml-1 mx-auto d-none d-sm-block" }, [
      _c("span", { staticClass: "text-address-footer" }, [
        _vm._v(_vm._s(_vm.fullAddress))
      ])
    ]),
    _c(
      "div",
      { staticClass: "mx-auto list-social" },
      _vm._l(_vm.socialMedias, function(item, index) {
        return _c(
          "a",
          { key: index, attrs: { href: item.link, target: "new" } },
          [_c("i", { class: item.icon, attrs: { title: item.label } })]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }