<template>
    <footer :class="classList">
        <div class="ml-1 mx-auto d-none d-sm-block">
          <span class="text-address-footer">{{ fullAddress }}</span>
        </div>
        <div class="mx-auto list-social">
          <a v-for="(item, index) in socialMedias" :key="index" :href="item.link" target="new">
              <i :class="item.icon" :title="item.label"></i>
          </a>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    fixed: {
      type: Boolean,
      default: false
    },
    login: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fullAddress: 'RUA MEDIANEIRA, 81 LJ 01 | ALDEOTA | FORTALEZA-CE | BRASIL | +55 85 3133-8500',
      socialMedias: [
        { icon: 'fa fa-lg fa-youtube-play', label: 'Youtube', link: '//jetro.com.br' },
        { icon: 'fa fa-lg fa-twitter', label: 'Twitter', link: '//twitter.com/jetrooficial' },
        { icon: 'fa fa-lg fa-facebook', label: 'Facebook', link: '//facebook.com/jetrooficial' },
        { icon: 'fa fa-lg fa-instagram', label: 'Instagram', link: '//instagram.com/jetrooficial' }
      ]
    }
  },
  computed: {
    classList () {
      return [
        this.login ? '' : 'app-footer'
      ]
    }
  },
  mounted: function () {
    this.isFixed()
  },
  methods: {
    isFixed () {
      this.fixed ? document.body.classList.add('footer-fixed') : document.body.classList.remove('footer-fixed')
    }
  }
}
</script>

<style>
.list-social a {
    color: #835418;
    font-size: 16px;
    margin-left: 7px;
    width: 31px;
    border-radius: 3px;
    height: 30px;
    display: inline-block;
    background: #fffaf4;
    padding: 5px;
    text-align: center;
    font-family: Lato,Calibri,Arial,sans-serif;
    font-weight: 700;
}
.list-social a i {
    vertical-align: initial;
}
/* .social-icon {
  cursor: pointer;
  color: #f68b29;
  border: 1px solid #f68b29;
  border-radius: 50%;
  text-align: center;
  padding: .5em;
  margin-right: 5px;
  display: inline-block;
  line-height: 1;
}
a.social-icon {
  text-decoration: none;
  color: #f68b29;
}
a.social-icon:hover {
  color: #ffac5e;
}
.social-icon i {
  width: 1em;
  height: 1em;
  position: relative;
  display: block;
} */
</style>
