var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppHeaderDropdown",
    { attrs: { right: "", "no-caret": "" } },
    [
      _c("template", { slot: "header" }, [
        _c("img", {
          staticClass: "img-avatar",
          staticStyle: { width: "40px", height: "40px" },
          attrs: { src: _vm.profile.url },
          on: { error: _vm.getAvatar }
        })
      ]),
      _vm._v("\\\n  "),
      _c(
        "template",
        { slot: "dropdown" },
        [
          _c(
            "b-card",
            {
              staticClass: "card-accent-primary mb-0",
              staticStyle: { width: "25em" },
              attrs: { "footer-class": "bg-header-color p-1" }
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-sm-3 col-md-3 text-center pt-1 pl-0 pr-0"
                  },
                  [
                    _c("img", {
                      staticClass: "img-avatar",
                      staticStyle: { height: "3.8rem", width: "3.8rem" },
                      attrs: { src: _vm.profile.url },
                      on: { error: _vm.getAvatar }
                    })
                  ]
                ),
                _c("div", { staticClass: "col-sm-9 col-md-9" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-primary font-weight-bold mb-0 text-nowrap"
                    },
                    [_vm._v(_vm._s(_vm.profile.nome))]
                  ),
                  _c("p", { staticClass: "mb-0 text-nowrap" }, [
                    _vm._v(_vm._s(_vm.profile.nivel))
                  ]),
                  _c("p", { staticClass: "text-nowrap" }, [
                    _vm._v(_vm._s(_vm.profile.grupo))
                  ])
                ])
              ]),
              _c(
                "b-row",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-white float-right btn-small",
                          attrs: { variant: "primary", size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.logout()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "icon-logout icons" }),
                          _vm._v(" Sair\n          ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }