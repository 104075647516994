<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <img
        :src="profile.url"
        class="img-avatar"
        style="width: 40px; height: 40px;"
        @error="getAvatar" />
    </template>\
    <template slot="dropdown">
      <b-card class="card-accent-primary mb-0" footer-class="bg-header-color p-1" style="width: 25em">
        <div class="d-flex">
          <div class="col-sm-3 col-md-3 text-center pt-1 pl-0 pr-0">
            <img :src="profile.url" class="img-avatar" style="height: 3.8rem; width: 3.8rem;" @error="getAvatar" />
          </div>
          <div class="col-sm-9 col-md-9">
            <p class="text-primary font-weight-bold mb-0 text-nowrap">{{ profile.nome }}</p>
            <p class="mb-0 text-nowrap">{{ profile.nivel }}</p>
            <p class="text-nowrap">{{ profile.grupo }}</p>
          </div>
        </div>
        <b-row slot="footer">
          <b-col sm="12" md="12">
            <b-button variant="primary" class="text-white float-right btn-small" size="sm" @click="logout()">
              <i class="icon-logout icons"></i> Sair
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
import session from '@/shared/session'

export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  computed: {
    profile: function() {
      const profile = (session.get('profile'));
      const schema = (session.get('schema'));
      return {
        ...profile,
        url: profile.foto ? `${process.env.VUE_APP_API_URL}get-photo/${schema}/${profile.foto}` : './img/avatars/no-photo.jpg'
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('LOGOUT');
      this.$router.push({path: '/'});
    },
    getAvatar(e) {
      e.target.src = './img/avatars/no-photo.jpg'
    }
  }
}
</script>
