<template>
  <div>
    <AppHeaderDropdown right no-caret>
      <template slot="header">
        <img
          :src="profile.url"
          class="img-avatar"
          style="width: 40px; height: 40px;"
          @error="getAvatar" />
      </template>\
      <template slot="dropdown">
        <b-dropdown-header tag="div" class="text-center bg-header-color col-sm-12 col-md-12">
          <p class="mb-0 text-primary font-weight-bold text-nowrap">{{ profile.nome }}</p>
          <p class="mb-0 text-nowrap">{{ profile.nivel }}</p>
          <p class="mb-0 text-nowrap">{{ profile.grupo }}</p>
        </b-dropdown-header>
        <b-dropdown-item @click="monitoramento()"><i class="icon-graph icons" /> Monitoramento</b-dropdown-item>
        <b-dropdown-item @click="logout()"><i class="icon-logout icons" /> Sair</b-dropdown-item>
      </template>
    </AppHeaderDropdown>

    <b-modal id="modal-monitoramento" centered size="md" hide-header body-class="pb-0">
      <template slot="default">
        <b-row class="mb-1">
          <b-col sm="12" md="12" lg="12">
            <c-table :table-data="monitoramentoInfo" :fields="fields" striped small caption="Monitoramento" :per-page="25"></c-table>
          </b-col>
        </b-row>
      </template>

      <template slot="modal-footer" slot-scope="{ ok, cancel }">
        <b-button size="sm" variant="outline-primary" @click="cancel()">
          Fechar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
import cTable from '@/core/components/Table.vue'
import session from '@/shared/session'
import moment from 'moment';
import API from '@/core/api';

export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown,
    cTable
  },
  data: () => {
    return {
      monitoramentoInfo: [],
      fields: [
        {key: 'descricao', label: 'Loja', sortable: true},
        {key: 'datahora', label: 'Última Sincronização', sortable: true, datetime: true}
      ]
    }
  },
  computed: {
    profile: function() {
      const profile = (session.get('profile'));
      const schema = (session.get('schema'));
      return {
        ...profile,
        url: profile.foto ? `${process.env.VUE_APP_API_URL}get-photo/${schema}/${profile.foto}` : './img/avatars/no-photo.jpg'
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('LOGOUT');
      this.$router.push({path: '/'});
    },
    getAvatar(e) {
      e.target.src = './img/avatars/no-photo.jpg'
    },
    async monitoramento() {
      try {
        const monitoramentoAPI = new API('api/monitoramento')
        const { data } = await monitoramentoAPI.get()
        this.monitoramentoInfo = data.data
        this.$bvModal.show('modal-monitoramento')
      } catch(e) {
        console.error(e)
      }
    }
  }
}
</script>