var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "AppHeaderDropdown",
        { attrs: { right: "", "no-caret": "" } },
        [
          _c("template", { slot: "header" }, [
            _c("img", {
              staticClass: "img-avatar",
              staticStyle: { width: "40px", height: "40px" },
              attrs: { src: _vm.profile.url },
              on: { error: _vm.getAvatar }
            })
          ]),
          _vm._v("\\\n    "),
          _c(
            "template",
            { slot: "dropdown" },
            [
              _c(
                "b-dropdown-header",
                {
                  staticClass:
                    "text-center bg-header-color col-sm-12 col-md-12",
                  attrs: { tag: "div" }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-0 text-primary font-weight-bold text-nowrap"
                    },
                    [_vm._v(_vm._s(_vm.profile.nome))]
                  ),
                  _c("p", { staticClass: "mb-0 text-nowrap" }, [
                    _vm._v(_vm._s(_vm.profile.nivel))
                  ]),
                  _c("p", { staticClass: "mb-0 text-nowrap" }, [
                    _vm._v(_vm._s(_vm.profile.grupo))
                  ])
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.monitoramento()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "icon-graph icons" }),
                  _vm._v(" Monitoramento")
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.logout()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-logout icons" }), _vm._v(" Sair")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-monitoramento",
            centered: "",
            size: "md",
            "hide-header": "",
            "body-class": "pb-0"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function(ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "outline-primary" },
                      on: {
                        click: function($event) {
                          return cancel()
                        }
                      }
                    },
                    [_vm._v("\n        Fechar\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "default" },
            [
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "12", lg: "12" } },
                    [
                      _c("c-table", {
                        attrs: {
                          "table-data": _vm.monitoramentoInfo,
                          fields: _vm.fields,
                          striped: "",
                          small: "",
                          caption: "Monitoramento",
                          "per-page": 25
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }