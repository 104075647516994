export default {
  items: [
    {
      modo: 1,
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'cui-dashboard',
      class: 'text-white'
    },
    {
      modo: 1,
      name: 'Vendas',
      url: '/vendas',
      icon: 'cui-dollar'
    },
    {
      modo: 1,
      name: 'Consignações',
      url: '/consignacoes',
      icon: 'cui-list'
    },
    {
      modo: 1,
      name: 'Metas',
      url: '/metas',
      icon: 'cui-speedometer'
    },
    {
      modo: 1,
      name: 'Fluxo de Atendimentos',
      url: '/fluxo-atendimentos',
      icon: 'cui-graph'
    },
    {
      modo: 1,
      name: 'Produtos Mais Vendidos',
      url: '/produtos-mais-vendidos',
      icon: 'cui-thumb-up'
    },
    {
      modo: 1,
      name: 'Produtos Menos Vendidos',
      url: '/produtos-menos-vendidos',
      icon: 'cui-thumb-down'
    },
    {
      modo: 1,
      name: 'Mix de Produtos Vendidos',
      url: '/mix-produtos-vendidos',
      icon: 'cui-layers'
    },
    {
      modo: 1,
      name: 'Clientes Atendidos',
      url: '/clientes-atendidos',
      icon: 'cui-people'
    },
    {
      modo: 1,
      name: 'Vendas Perdidas',
      url: '/vendas-perdidas',
      icon: 'cui-user-unfollow'
    },
    {
      modo: 1,
      name: 'NPS',
      url: '/nps',
      icon: 'cui-note'
    },
    {
      modo: 2,
      name: 'Fila de Atendimento',
      url: '/fila',
      icon: 'icon-layers'
    }
  ]
}
