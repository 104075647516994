<template>
  <div class="app">
    <loader />
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <SidebarToggler class="d-md-down-none" display="lg" :defaultOpen=true />
      <b-link class="navbar-brand" to="/">
        <img class="navbar-brand-full" src="img/brand/logo.png" height="22" alt="Taylor Logo">
      </b-link>
      <b-navbar-nav class="ml-auto">
        <span class="badge badge-warning" v-if="isTest">Ambiente de Teste</span>
        <DefaultHeaderDropdownAccntList/>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed minimized>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="navigation"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <div class="text-primary font-weight-bold h4 ml-3 mt-2 mb-0">
          <i class="icons" :class="routeIcon"></i> {{ routeName }}
        </div>
        <div class="container-fluid p-2">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <DefaultAside/>
      </AppAside>
    </div>
    <Footer class="app-footer" />
  </div>
</template>

<script>
import nav from '@/_nav'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarNav, Aside as AppAside, AsideToggler } from '@coreui/vue'
import Footer from '@/core/components/Footer'
import Loader from '@/core/components/Loader'
import DefaultAside from './DefaultAside'
import SidebarMinimizer from './SidebarMinimizer'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import DefaultHeaderDropdownAccntList from './DefaultHeaderDropdownAccntList'
import session from '../shared/session'

export default {
  name: 'DefaultContainer',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    Footer,
    Loader,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    DefaultHeaderDropdownAccntList,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data () {
    return {
      nav: nav.items,
      isTest: window.location.host.includes('teste')
    }
  },
  computed: {
    routeName () {
      return this.$route.name
    },
    routeIcon () {
      const nav = this.nav.find(n => n.url === this.$route.path) || {icon: ''}
      return nav.icon
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    },
    navigation () {
      return this.nav.filter(n => n.modo === (session.get('modo') || 1));
    }
  },
  created() {
    document.body.classList.add('sidebar-minimized')
  }
}
</script>